import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, InputCell, Loader, PopupFullPage, RegexUtils } from '../../../core';
import Icon from '../../../core/components/icon-svg/icon';
import { DriveLogSubHeader } from '../../../drive-log';
import EpeModal from './modals/epe-modal';
import ServiceNumberModal from './modals/service-number-modal';
import './service-number.scss';

/**
 * Renders an <ServiceNumber /> full page
 */
const ServiceNumber = (props) => {
	const { t } = useTranslation();
	const {
		navigate,
		title = t('dl:dl-new-page.title'),
		noServiceNumberOption = true,
		showDLExistsModal,
		showAttachmentNotValidModal,
		buttonValidated,
		// tmp disable tn/ms / epe
		//isEmpty = false,
	} = props;

	const [serviceNumber, setServiceNumber] = useState('');
	const [isFormValid, setIsFormValid] = useState(false);
	const [isValidated, setIsValidated] = useState(false);
	const [showEpeModal, setShowEpeModal] = useState(false);

	useEffect(() => {
		setIsValidated(false);
	}, [buttonValidated]);

	const [showNoServiceNumberModal, setShowNoServiceNumberModal] = useState(false);
	const toggleNoServiceNumberModal = () => {
		setIsValidated(false);
		setShowNoServiceNumberModal(!showNoServiceNumberModal);
	};

	const validCharacters = useMemo(() => /^[0-9a-zA-Z]*$/, []);

	const validate = () => {
		setIsValidated(true);
		const trimmedServiceNumber = serviceNumber.replace(/^0+/, '');
		// tmp disable tn/ms / epe
		/* if (isEmpty && (serviceNumber === '681' || serviceNumber === '683')) {
			setShowEpeModal(true);
			setIsValidated(false);
		} else*/
		navigate({ serviceNumber: trimmedServiceNumber });
	};

	useEffect(() => {
		const formValid =
			serviceNumber && serviceNumber !== '0' && !!serviceNumber.match(validCharacters);
		setIsFormValid(formValid);
	}, [validCharacters, serviceNumber]);

	/**
	 * @function handleChangeServiceNumber - checks if the entered value matches the format of the expected value
	 * @param {String} number - the entered number
	 * @return {void}
	 */
	const handleChangeServiceNumber = (number) => {
		if (number.match(validCharacters)) {
			setServiceNumber(number);
		}
	};

	const handleReset = () => {
		setServiceNumber('');
	};

	return (
		<PopupFullPage title={title}>
			<div className="service-number-page">
				<DriveLogSubHeader subHeader={t('dl:service-number.title')} />
				<div className="service-number-page__cells">
					<InputCell
						className="service-number-page__cell"
						regex={RegexUtils.alphaNumericWithFiveCharacters}
						value={serviceNumber}
						onChange={handleChangeServiceNumber}
						onValidate={isFormValid && validate}
					/>
					<Icon
						aria-label="Close"
						className="service-number__icon-close"
						name="close"
						onClick={handleReset}
					/>
				</div>
				<Loader isLoading={!!isValidated && !showDLExistsModal && !showAttachmentNotValidModal}>
					<Button
						disabled={!isFormValid || isValidated}
						className="service-number-page__button"
						onClick={validate}
					>
						{t('core:popup-full-page.button.validate')}
					</Button>
					{noServiceNumberOption && (
						<Button className="button button--link" onClick={toggleNoServiceNumberModal}>
							{t('dl:service-number.no-service')}
						</Button>
					)}
				</Loader>
			</div>
			<ServiceNumberModal
				showModal={showNoServiceNumberModal}
				toggleModal={toggleNoServiceNumberModal}
				navigate={navigate}
			/>
			<EpeModal
				showModal={showEpeModal}
				setShowModal={setShowEpeModal}
				navigate={navigate}
				setServiceNumber={setServiceNumber}
				serviceNumber={serviceNumber}
			/>
		</PopupFullPage>
	);
};

ServiceNumber.propTypes = {
	navigate: PropTypes.func,
};

export default ServiceNumber;
