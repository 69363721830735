import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import localForage from '../../../../../../config/local-forage/index';
import { Button, DateUtils, Icon } from '../../../../../core';
import { addObservation } from '../../../../../observation/observation.services';

import './station-row.scss';

const StationRow = ({ stationData, isDeparture = false, isArrival = false }) => {
	const {
		departure_time_seconds: departureTime,
		station_name: stationName,
		station_trigram: stationTrigram,
		line,
		mission_code: missionCode,
		is_terminal: isTerminalStation = false,
		is_departure: isDepartureStation = false,
		is_disabled: isDisabled,
	} = stationData;

	// const { t } = useTranslation();
	const { id: driveLogId, missionId } = useParams();
	const { formatTimeFromSeconds } = DateUtils;
	// const [showAlertModal, setShowAlertModal] = useState(false);
	const [isServed, setIsServed] = useState(stationData.is_served);

	const hasBorder = isTerminalStation || isDepartureStation;
	const hasDashedBorder = isDeparture || isArrival;

	useEffect(() => {
		// Retrieve the object of station states from localForage if it exists
		localForage.getItem(`stationsState-${missionCode}`).then((savedStationsState) => {
			if (savedStationsState && savedStationsState[stationTrigram] !== undefined) {
				setIsServed(savedStationsState[stationTrigram]);
			}
		});
	}, [stationTrigram, missionCode]);

	// const handleShowAlertModal = () => {
	// 	setShowAlertModal(true);
	// };

	const handleToggleStation = () => {
		// Invert the value of isServed
		const newIsServed = !isServed;
		setIsServed(newIsServed);
		stationData.is_served = newIsServed;

		// Retrieve the object of station states from localForage
		localForage.getItem(`stationsState-${missionCode}`).then((savedStationsState) => {
			const updatedStationsState = savedStationsState || {};
			// Update the state of the station
			updatedStationsState[stationTrigram] = newIsServed;
			// Save the updated object in localForage
			localForage.setItem(`stationsState-${missionCode}`, updatedStationsState);
		});

		const observation = {
			observation_type: 'comment',
			creation_date: new Date(),
			content: {
				comment: newIsServed
					? `Une desserte supplémentaire a été effectuée en gare de ${stationName}`
					: `La gare ${stationName} n'a pas été desservie`,
			},
			mission_id: missionId,
		};

		addObservation(driveLogId, observation, false);
	};

	const isDisabledStationBackground = isDisabled ? 'station-row__disabled' : '';
	const isDisabledStationText = isDisabled
		? 'station-row-text-color__disabled'
		: 'station-row-text-color';
	const isDisabledDepartureTime = isDisabled ? 'station-row-hour__disabled' : '';

	return (
		<li className={'station-row '}>
			<div
				className={classNames(
					`station-row-container ${isDisabledStationBackground}`,

					{
						'station-row-container__not-served': !isServed,
						[`station-row-container__border-${line.toUpperCase() === 'A' ? 'red' : 'blue'}`]:
							hasDashedBorder && hasBorder,
						[`station-row-container__border-dashed-${line.toUpperCase() === 'A' ? 'red' : 'blue'}`]:
							!(hasDashedBorder && hasBorder) && hasDashedBorder,
					},
				)}
			>
				<div
					className={classNames(`station-row-hour ${isDisabledDepartureTime}`, {
						'station-row__not-served': !isServed,
					})}
				>
					{formatTimeFromSeconds(departureTime)}
				</div>

				<div
					className={classNames(`station-row-text ${isDisabledStationText}`, {
						'station-row__not-served': !isServed && !isDisabled,
					})}
				>
					{stationName}
				</div>
				<div className={'station-row-info'}></div>
				{/* {!isDisabled && (
					<Button className="station-row-button" light onClick={() => handleShowAlertModal()}>
						<Icon name="attention-alert" className="station-row-icon" />
						{t('mission:mission-details.alert')}
					</Button>
				)} 

				{showAlertModal && (
					<AttentionAlertModal setShowModal={setShowAlertModal} station={stationData} />
				)}*/}
			</div>

			<Button onClick={() => handleToggleStation()} className="station-row-button-icon">
				<Icon
					name={!isDisabled ? 'plus' : ''}
					className={isServed ? 'station-row-button-icon__delete' : 'station-row-button-icon__add'}
				/>
			</Button>
		</li>
	);
};

StationRow.propTypes = {
	stationData: PropTypes.shape({
		departure_time_seconds: PropTypes.number.isRequired,
		station_name: PropTypes.string.isRequired,
		station_trigram: PropTypes.string.isRequired,
		line: PropTypes.string.isRequired,
		mission_code: PropTypes.string.isRequired,
		is_served: PropTypes.bool.isRequired,
		is_terminal: PropTypes.bool.isRequired,
		is_departure: PropTypes.bool.isRequired,
		is_disabled: PropTypes.bool.isRequired,
	}).isRequired,
	isDeparture: PropTypes.bool,
	isArrival: PropTypes.bool,
};

export default StationRow;
